
import { defineComponent, ref, onBeforeUnmount, onMounted} from "vue";
import { useCityData } from '@/assets/modulosTS/getDataCity';
import { weatherCodes } from '@/assets/modulosTS/weatherCodes';

import { propiedadesComunes, propiedadesMenosComunes, propiedadesComunesEspacios, propiedadesMenosComunesEspacios } from '@/assets/modulosTS/moreDetails';

export default defineComponent({
  name: 'NextHoursView',

  setup() {

    // Declaramos las variables reactivas que vamos a utilizar
    const cityDataRef = ref<{ name: string; country: string } | any>(null);
    const bool = ref<boolean>(false);
    const cityValuesRef = ref<any>({});
    const currentTime = ref<{ hour: number; minute: string; ampm: string }>({
      hour: 0,
      minute: '00',
      ampm: 'AM',
    });
    const sunriseTime = ref<number | any>();
    const sunsetTime = ref<number | any>();
    const weatherCode = ref<string>();
    const backgroundImageClass = ref<string>('');


    // Esta función manejará el cambio en el almacenamiento local (localStorage)
    const handleLocalStorageChange = async () => {
      try {
        const { cityData, cityValues } = await useCityData() as {
          cityValues: any;
          cityData: any;
        };

        // Asignamos los datos obtenidos a las variables reactivas correspondientes
        cityDataRef.value = cityData.value;
        cityValuesRef.value = cityValues._value.data;
        bool.value = true;
        updateSunriseSunsetTimes();
        changeBackground(cityValuesRef.value.current_weather.time)
        // Llamar a formatHour para calcular currentTime
        formatHour(cityValuesRef.value.current_weather.time, true);

      } catch (error) {
        console.error('Error al obtener los datos de la ciudad:', error);
      }
    };


    // Función para dar formato a la hora
    const formatHour = (dateTimeString: any, mainLogic = false) => {
      const dateTime = new Date(dateTimeString);
      const hour = parseInt(dateTime.getHours().toString(), 10);
      const minute = dateTime.getMinutes().toString().padStart(2, '0');
      const ampm = hour >= 12 ? 'PM' : 'AM';
      // Si se está llamando desde la lógica principal, calculamos el código meteorológico
      if (mainLogic) {
        weatherCode.value = WeatherDescription();
      }
      // Actualizamos la hora actual
      currentTime.value = { hour, minute, ampm };
      return { hour, minute, ampm }
    };

    // Función para cambiar el fondo según la hora del día
    const changeBackground = (dateTimeString: any) => {
      const hour = formatHour(dateTimeString).hour;
      const sunset = sunsetTime.value.split(':').map(Number);
      const sunrise = sunriseTime.value.split(':').map(Number);
      if (hour >= (sunrise[0] + 1) && hour < sunset[0]) {
        backgroundImageClass.value = 'backgroundDay';
      } else if (hour >= (sunset[0] + 1) || hour < sunrise[0]) {
        backgroundImageClass.value = 'backgroundNight';
      } else if (hour >= sunrise[0] && hour < (sunrise[0] + 1)) {
        backgroundImageClass.value = 'backgroundSunrise';
      } else if (hour >= sunset[0] && hour < (sunset[0] + 1)) {
        backgroundImageClass.value = 'backgroundSunset';
      }
      return '';
    };

    // Función para obtener la descripción del tiempo según el código meteorológico
    const WeatherDescription = () => {
      const matchingCode = weatherCodes.find(item => item.code.includes(cityValuesRef.value.current_weather.weathercode));
      return matchingCode ? matchingCode.description : 'Código meteorológico no encontrado';
    };

    // Función para dar formato a la hora y minutos
    const formatHourMeth = (dateTimeString: any) => {
      const formattedTime = formatHour(dateTimeString);
      return `${formattedTime.hour}:${formattedTime.minute}`;
    };

    // Función para actualizar las horas de amanecer y atardecer
    const updateSunriseSunsetTimes = () => {
      sunriseTime.value = formatHourMeth(cityValuesRef.value.daily.sunrise[0]);
      sunsetTime.value = formatHourMeth(cityValuesRef.value.daily.sunset[0]);
    };

    // Agregamos un event listener personalizado llamado 'localStorageChanged' que activará handleLocalStorageChange cuando se llame.
    window.addEventListener('localStorageChanged', handleLocalStorageChange);

    // Eliminamos el event listener cuando el componente se desmonte para evitar posibles problemas de memoria.
    onBeforeUnmount(() => {
      window.removeEventListener("localStorageChanged", handleLocalStorageChange);
    });

    // Al montar el componente, llamamos a handleLocalStorageChange para inicializar los datos y tiempos.
    onMounted(() => {
      handleLocalStorageChange();
    });

    // Devolvemos las variables y funciones que se utilizarán en el componente.
    return {
      cityDataRef,
      cityValuesRef,
      bool,
      currentTime,
      sunriseTime,
      sunsetTime,
      weatherCode,
      backgroundImageClass,
      propiedadesMenosComunes,
      propiedadesComunes,
      propiedadesMenosComunesEspacios,
      propiedadesComunesEspacios,
      formatHour,
      formatHourMeth,
      updateSunriseSunsetTimes
    };
  },
});
