import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0557763f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opcionesBusqueda" }
const _hoisted_2 = { class: "citiesSearch" }
const _hoisted_3 = { class: "citiesContainer" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      id: "search",
      type: "search",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchInput) = $event)),
      placeholder: "Ciudad para buscar...",
      autofocus: "",
      required: "",
      autocomplete: "off",
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fetchDataInput && _ctx.fetchDataInput(...args))),
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.fetchDataInput && _ctx.fetchDataInput(...args)))
    }, null, 544), [
      [_vModelText, _ctx.searchInput]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cityList, (city, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "cittyUnic",
            key: index,
            onClick: ($event: any) => (_ctx.sendCityData(city))
          }, [
            _createElementVNode("b", null, _toDisplayString(city.name) + ", " + _toDisplayString(city.country), 1)
          ], 8, _hoisted_4))
        }), 128))
      ])
    ])
  ]))
}