
import { ref } from 'vue';
export default {
  name: 'ThemeSwitcher',
  setup() {

    const isDarkTheme = ref(localStorage.getItem('theme') === 'dark');
    //Método para controlar el tema, blanco o negro según la opción escogida
    const toggleTheme = () => {
      const newTheme = isDarkTheme.value ? 'light' : 'dark';
      localStorage.setItem('theme', newTheme);
      isDarkTheme.value = !isDarkTheme.value;
      const localStorageChangeEvent = new Event('changeTheme');
      window.dispatchEvent(localStorageChangeEvent);
    };

    return {
      isDarkTheme,
      toggleTheme,
    };
  },
};
