
import { defineComponent } from 'vue';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import ThemeSwitcher from '@/components/ThemeSwitcher.vue';

export default defineComponent({
  name: 'BannerWeather',
  components: {
    ThemeSwitcher

  },
  setup() {
    // Variable reactiva que controla si el modal está abierto o cerrado
    const isModalOpen = ref(false);

    // Función para alternar la apertura/cierre del modal
    const toggleModal = () => {
      isModalOpen.value = !isModalOpen.value;
    };

    // Función para cerrar el modal
    const closeModal = () => {
      isModalOpen.value = false;
    };

    // Función para reiniciar el contador de búsquedas premium en el localStorage
    const resetPremium = () => {
      localStorage.setItem('countBusquedas', '0')
    };

    // Variable reactiva que controla si el menú está abierto o cerrado (inicialmente abierto)
    const isMenuOpen = ref(true);

    // Variable reactiva para realizar un seguimiento de si el menú ha sido revisado o no
    const checkMenu = ref(false);

    // Función para alternar la apertura/cierre del menú y gestionar el tamaño de la ventana
    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    // Función que gestiona el tamaño de la ventana del navegador
    const handleWindowResize = () => {
      if (window.innerWidth < 1070 && checkMenu.value != true) {
        isMenuOpen.value = false; // Cierra el menú si el ancho es menor que 1070px
        checkMenu.value = true;
      } else if (window.innerWidth > 1070 && checkMenu.value != false) {
        checkMenu.value = false; // Abre el menú si el ancho es mayor o igual a 1070px
        isMenuOpen.value = true;
      }
    };

    // Observa el tamaño de la ventana y actualiza el estado del menú
    const resizeHandler = () => {
      handleWindowResize();
    };

    // Maneja el tamaño de la ventana al cargar el componente
    onMounted(() => {
      handleWindowResize();
      window.addEventListener('resize', resizeHandler);
    });

    // Limpia el evento resize al desmontar el componente
    onBeforeUnmount(() => {
      window.removeEventListener('resize', resizeHandler);
    });

    //retorno varariables al template
    return {
      isModalOpen,
      isMenuOpen,
      toggleMenu,
      toggleModal,
      closeModal,
      resetPremium

    };
  }

});
