import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-theme"]
const _hoisted_2 = ["data-theme"]
const _hoisted_3 = ["data-theme"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerWeather = _resolveComponent("BannerWeather")!
  const _component_CittySearch = _resolveComponent("CittySearch")!
  const _component_PremiumSubscriptionModal = _resolveComponent("PremiumSubscriptionModal")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_mainFooter = _resolveComponent("mainFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "backgroundGradient",
      "data-theme": _ctx.componentTypes[5]
    }, null, 8, _hoisted_1),
    _createVNode(_component_BannerWeather, {
      "data-theme": _ctx.componentTypes[0]
    }, null, 8, ["data-theme"]),
    _createVNode(_component_CittySearch, {
      "data-theme": _ctx.componentTypes[1]
    }, null, 8, ["data-theme"]),
    _createVNode(_component_PremiumSubscriptionModal, {
      "data-theme": _ctx.componentTypes[2]
    }, null, 8, ["data-theme"]),
    _createElementVNode("div", {
      class: "optionsWeather",
      "data-theme": _ctx.componentTypes[3]
    }, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createTextVNode("24h")
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/about" }, {
        default: _withCtx(() => [
          _createTextVNode("Semanal")
        ]),
        _: 1
      })
    ], 8, _hoisted_2),
    _createElementVNode("main", {
      class: "mainContent",
      "data-theme": _ctx.componentTypes[6]
    }, [
      _createVNode(_component_router_view)
    ], 8, _hoisted_3),
    _createVNode(_component_mainFooter, {
      "data-theme": _ctx.componentTypes[4]
    }, null, 8, ["data-theme"])
  ], 64))
}