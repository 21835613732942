
import { defineComponent } from "vue";
import BannerWeather from "@/components/BannerWeather.vue";
import CittySearch from "@/components/CittySearch.vue";
import PremiumSubscriptionModal from "@/components/PremiumSubscriptionModal.vue";
import mainFooter from "@/components/mainFooter.vue";

import { ref, onMounted, onBeforeUnmount } from 'vue';
export default defineComponent({
  components: {
    BannerWeather,
    CittySearch,
    PremiumSubscriptionModal,
    mainFooter

  },
  setup() {
    // Definimos una variable reactiva llamada isDarkTheme para controlar el tema.
    const isDarkTheme = ref<any>('');
    // Inicializamos componentTypes con un valor predeterminado (puede ser light o dark).
    const componentTypes = ref([
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ]);
    const componentDark = [
      'darkBannerWeather',
      'darkCittySearch',
      'darkPremiumSubscriptionModal',
      'darkOptionsWeather',
      'darkMainFooter',
      'darkbackgroundGradient',
      'darkMainRouterLink'
    ];
    const componentLight = [
      'lightBannerWeather',
      'lightCittySearch',
      'lightPremiumSubscriptionModal',
      'lightOptionsWeather',
      'lightMainFooter',
      'lightbackgroundGradient',
      'lightMainRouterLink'
    ];
    
    // Esta función manejará el cambio en el almacenamiento local (localStorage).
    const handleLocalStorageChange = () => {
      // Obtenemos el valor almacenado en 'theme' en localStorage y lo asignamos a isDarkTheme.
      isDarkTheme.value = localStorage.getItem('theme');
      if (isDarkTheme.value == 'dark') {
        componentTypes.value = componentDark
      } else {
        componentTypes.value = componentLight
      }
    }
    // Verificamos si el tema no está definido en localStorage, y si es así, lo establecemos en "light".
    if (!localStorage.getItem('theme')) {
      localStorage.setItem('theme', 'light');
    }
    
    // Agregamos un event listener personalizado llamado 'changeTheme' que activará handleLocalStorageChange cuando se llame.
    window.addEventListener('changeTheme', handleLocalStorageChange);

    // Eliminamos el event listener cuando el componente se desmonte para evitar posibles pérdidas de memoria.
    onBeforeUnmount(() => {
      window.removeEventListener("changeTheme", handleLocalStorageChange);
    });

    // Al montar el componente, llamamos a handleLocalStorageChange para inicializar isDarkTheme con el valor actual.
    onMounted(() => {
      handleLocalStorageChange();
    });

    // Devolvemos isDarkTheme para que pueda ser utilizado en el componente.
    return {
      componentTypes
    };
  }
});
