
import { ref, onBeforeUnmount } from 'vue';
export default {
  name: 'PremiumSubscriptionModal',
  setup() {
    
    //Creación de variables reactivas
    const isModalOpen = ref(false);

    //Método para abrir o cerrar modal
    const toggleModal = () => {
      isModalOpen.value = !isModalOpen.value;
    };
    //Método cerrar modal
    const closeModal = () => {
      isModalOpen.value = false;
    };
    
    // Reiniciar countBusquedas al reiniciar la página
    const resetCountOnUnload = () => {
      localStorage.setItem('countBusquedas', '0');
    };

    // Agregar un listener para hacer check
    window.addEventListener('checkCountBusquedas', toggleModal);

    // Utilizamos onBeforeUnmount para resetear el localStorage
    onBeforeUnmount(() => {
      resetCountOnUnload();
    });

    return {
      isModalOpen,
      toggleModal,
      closeModal,
    };
  }
};
